import { memo, useMemo } from 'react';
import AutoComplete from '../auto-complete/AutoComplete';
import TextField from '../text-field/TextField';
import { ProjectDTO2 } from '../../api/api';

interface Props {
  projectNumberChange: (value: any) => void;
  options: ProjectDTO2[];
  isLoadingProjectNumbers?: boolean;
  value?: MappedProjectNumber;
  disabled?: boolean;
  disableClearable?: boolean;
}

export interface MappedProjectNumber {
  label: string;
  value: ProjectDTO2;
}

export const projectNumberToOption = (p: ProjectDTO2): MappedProjectNumber => {
  const label = `${p.id} ${p.name ? '- ' + p.name : ''} ${p.legalEntityId ? '(' + p.legalEntityId + ')' : ''}`;
  return {
    label: label,
    value: p
  };
};

const ProjectNumberDropdown = (props: Props) => {
  const { value, projectNumberChange, disabled, disableClearable = false, options, isLoadingProjectNumbers } = props;

  const mappedProjectNumbers = useMemo(() => {
    return options.map((p) => projectNumberToOption(p));
  }, [options]);

  return (
    <AutoComplete
      onChange={(_, value) => projectNumberChange(value)}
      value={value}
      options={mappedProjectNumbers}
      renderInput={(params) => <TextField {...params} label={'Projektnummer'} fullWidth required />}
      disabled={disabled}
      loading={isLoadingProjectNumbers}
      fullWidth
      noOptionsText={'Intet projektnummer' ?? ''}
      disableClearable={disableClearable}
    />
  );
};

export default memo(ProjectNumberDropdown);
